// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-details-js": () => import("./../src/templates/projectDetails.js" /* webpackChunkName: "component---src-templates-project-details-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cctv-js": () => import("./../src/pages/cctv.js" /* webpackChunkName: "component---src-pages-cctv-js" */),
  "component---src-pages-consultancy-js": () => import("./../src/pages/consultancy.js" /* webpackChunkName: "component---src-pages-consultancy-js" */),
  "component---src-pages-corporate-page-js": () => import("./../src/pages/corporate-page.js" /* webpackChunkName: "component---src-pages-corporate-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("./../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-marketing-digital-js": () => import("./../src/pages/marketing-digital.js" /* webpackChunkName: "component---src-pages-marketing-digital-js" */),
  "component---src-pages-online-shop-js": () => import("./../src/pages/online-shop.js" /* webpackChunkName: "component---src-pages-online-shop-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-provider-wisp-js": () => import("./../src/pages/provider-wisp.js" /* webpackChunkName: "component---src-pages-provider-wisp-js" */),
  "component---src-pages-sended-email-js": () => import("./../src/pages/sended-email.js" /* webpackChunkName: "component---src-pages-sended-email-js" */),
  "component---src-pages-software-developer-js": () => import("./../src/pages/software-developer.js" /* webpackChunkName: "component---src-pages-software-developer-js" */),
  "component---src-pages-support-networks-js": () => import("./../src/pages/support-networks.js" /* webpackChunkName: "component---src-pages-support-networks-js" */),
  "component---src-pages-support-tec-js": () => import("./../src/pages/support-tec.js" /* webpackChunkName: "component---src-pages-support-tec-js" */)
}

